import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { firebase, firestore } from '../firebase'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
// import './ril-style.css'

import Layout from '../components/Layout'
import nl2br from '../hooks/nl2br'
import {
  artistDisplayName,
  Caption,
  kbImagifyDyn,
  kbImagifyOrig,
} from '../components/GalleryUtils'

// !!!
// 2 galerie versionen git historie

const trackArtworkView = async (artwork) => {
  try {
    // console.log('view open')
    const docRefArtwork = await firestore.collection('artworks').doc(artwork.id)
    if (docRefArtwork)
      docRefArtwork.update({
        trackArtworkView: firebase.firestore.FieldValue.increment(1),
      })
  } catch (error) {
    console.error(error, error?.message)
  }
}

const Gallery = ({ data, pageContext }) => {
  const [photoArray, setPhotoArray] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const artist = pageContext.artist
  const artworks = data.allArtwork.nodes.filter(node => node.catalog || node.catalogTop)
  console.log('artworks: ', artworks)
  const artworksSingle = artworks.filter((node) => node.files.length === 1) || []
  const artworksMultiple = artworks.filter((node) => node.files.length > 1) || []

  const onOpenClick = (index, array) => {
    setPhotoArray(array)
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <Layout
      location={typeof location !== `undefined` ? location : {}}
      showSlider={false}
      showFooter={false}
    >
      <div className="container my-5">
        <div className="row mb-5 ml-1">
          <Link className="text-dark" to="/gallery-overview">
            <i
              className="entypo-arrow-left mr-1"
              style={{
                fontSize: '13px',
                lineHeight: '1',
                // top: '-1px',
              }}
            />
            <span>zurück zur Galerieübersicht</span>
          </Link>
        </div>
        <div className="row mb-5">
          <div className="col-auto">
            <a
              href={kbImagifyOrig(artist.portrait_file)}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-auto"
                alt={`Portraitfoto ${artistDisplayName(artist)}`}
                style={{ width: '100px' }}
                src={kbImagifyDyn(artist.portrait_file)}
              />
            </a>
          </div>
          <div className="col">
            <h1 className="text-uppercase">{artistDisplayName(artist)}</h1>
            <p dangerouslySetInnerHTML={{ __html: nl2br(artist.vita_short) }} />
          </div>
        </div>
        <div className="row mt-5">
          {artworksSingle.map((artwork, index) => (
            <div
              key={artwork.id}
              className="col-4 col-sm-3 col-md-2 c-pointer p-3"
              onClick={() => onOpenClick(index, artworksSingle)}
            >
              <img
                className="w-100 h-auto"
                alt={artwork.title}
                src={kbImagifyDyn(artwork.files[0])}
              />
            </div>
          ))}
        </div>
        {artworksMultiple.length > 0 && (
          <div className="row mt-3">
            <div className="col-12">
              <h4>Arbeiten mit Einzelgalerien:</h4>
            </div>
          </div>
        )}
        {artworksMultiple.length > 0 && (
          <div className="row">
            {artworksMultiple.map((artwork) => (
              <div
                key={artwork.id}
                className="col-4 col-sm-3 col-md-2 c-pointer p-3"
                onClick={() =>
                  onOpenClick(
                    0,
                    artwork.files.map((file) => ({ ...artwork, files: [file] }))
                  )
                }
              >
                <img
                  className="w-100 h-auto"
                  alt={artwork.title}
                  src={kbImagifyDyn(artwork.files[0])}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={kbImagifyDyn(photoArray[photoIndex].files[0], 2000)}
          nextSrc={kbImagifyDyn(
            photoArray[(photoIndex + 1) % photoArray.length].files[0],
            2000
          )}
          prevSrc={
            photoArray[(photoIndex + photoArray.length - 1) % photoArray.length]
              .files[0]
          }
          onAfterOpen={() => trackArtworkView(photoArray[photoIndex])}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            const newPhotoIndex =
              (photoIndex + photoArray.length - 1) % photoArray.length
            trackArtworkView(photoArray[newPhotoIndex])
            setPhotoIndex(newPhotoIndex)
          }}
          onMoveNextRequest={() => {
            const newPhotoIndex = (photoIndex + 1) % photoArray.length
            trackArtworkView(photoArray[newPhotoIndex])
            setPhotoIndex(newPhotoIndex)
          }}
          imageCaption={
            <Caption artist={artist} artwork={photoArray[photoIndex]} />
          }
          imagePadding={40}
          enableZoom={false}
        />
      )}
    </Layout>
  )
}

export default Gallery

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    allArtwork(
      filter: {
        artistId: { eq: $id }
        deleted: { ne: true }
        fair: { eq: "2024-1" }
      }
      sort: { fields: [artworkIndex, title] }
    ) {
      nodes {
        artistId
        id
        deleted
        artworkIndex
        title
        height
        width
        depth
        technique
        price
        porto
        portoActive
        mainImage
        info
        files
        visible
        hidden
        catalog
        catalogTop
      }
    }
  }
`
